<main>
    <router-outlet></router-outlet>
</main>
<footer class="footer">
    <div class="container d-lg-flex justify-content-md-between">
        <div class="footer-links">
            <a href="https://www.rabobank.nl/particulieren/voorwaarden/" (click)="_tealiumAnalytics.logEvent('0.6');" target="_blank" rel="noopener noreferrer">Voorwaarden</a>
            <button class="btn btn-link" (click)="modalTerms.show(); _tealiumAnalytics.logEvent('0.7');">Disclaimer</button>
            <a href="https://www.rabobank.nl/particulieren/privacy/" (click)="_tealiumAnalytics.logEvent('0.8');" target="_blank" rel="noopener noreferrer">Privacy & cookies</a>
        </div>
    </div>
</footer>

<div class="loading d-flex align-items-center justify-content-center">
    <div class="loading-box">
        <img src="assets/img/loading.png" class="loading-icon" alt="Loading Icon" />
        <p>Aan het laden…</p>
    </div>
</div>

<app-modal #modalTerms dialogClasses="modal-lg">
    <div class="modal-header">
        <h5>&nbsp;</h5>
        <button class="modal-exit ml-auto" (click)="modalTerms.hide()">&#215;</button>
    </div>
    <div class="modal-body">
        <h2>Disclaimer</h2>
        <p>Rabobank biedt geen garantie dat de informatie op de Rabobank Intermediair Omgeving op elk moment volledig, juist, compleet en actueel is. De getoonde informatie kan op ieder moment zonder verdere aankondiging worden gewijzigd.</p>
    </div>
</app-modal>

<app-modal #modalSessionExpired class="modal-sm">
    <div class="modal-header">
        &nbsp;
    </div>
    <div class="modal-body">
        <h2>Jouw sessie is verlopen</h2>
        <p>Je bent te lang inactief geweest. Log opnieuw in.</p>
    </div>
    <div class="modal-footer">
        <button (click)="onClickRedirectToLogin()" class="btn btn-primary">Terug naar inloggen</button>
    </div>
</app-modal>
<ngx-guided-tour nextText="Volgende" [doneText]="_onboardingTour.doneButtonText" progressIndicatorLocation="_onboardingTour.progressIndicatorLocation.None"></ngx-guided-tour>

<app-modal #modalNewRelease class="modal-sm">
    <div class="modal-header">
        <h5>&nbsp;</h5>
        <button class="modal-exit ml-auto" (click)="modalNewRelease.hide()">&#215;</button>
    </div>
    <div class="modal-body">
        <h2>Nieuwe versie van RIO beschikbaar</h2>
        <p>Er is een nieuwe versie beschikbaar van RIO. Voor de beste prestaties raden wij aan om de pagina te vernieuwen, zodat de laatste wijzigingen kunnen worden opgehaald.</p>
    </div>
    <div class="modal-footer">
        <button (click)="reload()" class="btn btn-primary">Vernieuwen</button>
    </div>
</app-modal>