import { inject } from "@angular/core";
import { AccessManagerService } from "../services/access-manager.service";
import { UiHelperService } from "../services/ui-helper.service";
import { LoginStatus } from "../enums/enums";
import { Router } from "@angular/router";

export function canActivate(): boolean {
    const _accessManager = inject(AccessManagerService);
    const _uiHelper = inject(UiHelperService);
    const _route = inject(Router);

    if (_accessManager.loginStatus$.value != LoginStatus.LoggedIn) {
        _route.navigate(['/login'])
        return false;
    }
    return true;
};

export const LoggedInUserGuard = () => canActivate();