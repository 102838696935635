import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './components/header/header.component';
import { NumberOnlyInputDirective } from './directives/numeric.directive';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AlertComponent } from './components/alert/alert.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ModalComponent } from './components/modal/modal.component';
import { SearchComponent } from './components/search/search.component';
import { NavOptionsComponent } from './components/nav-options/nav-options.component';
import { BackButtonComponent } from './components/back-button/back-button.component';
import { CurrencyNLPipe } from './pipes/currency-nl.pipe';
import { DateNLPipe } from './pipes/date-nl.pipe';
import { PercentageNLPipe } from './pipes/percentage-nl.pipe';
import { FlagNLPipe } from './pipes/flag-nl.pipe';
import { CookieNotificationComponent } from './components/cookie-notification/cookie-notification.component';
import { ContactFormComponent } from './components/contact-form/contact-form.component';
import { RecaptchaModule } from 'ng-recaptcha';
import { MyProfileComponent } from './components/my-profile/my-profile.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PasswordValidatorComponent } from './components/password-validator/password-validator.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { CustomTitleCasePipe } from './pipes/custom-titlecase.pipe';
import { FeedbackRatingComponent } from './feedback-rating/feedback-rating.component';
import { FaqComponent } from './components/faq/faq.component';
import { BreadcrumbModule } from 'xng-breadcrumb';
import { ScrollTrackerDirective } from './directives/scroll-tracker.directive';
import { AutoSearchComponent } from './components/auto-search/auto-search.component';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { LeadTimeComponent } from './components/lead-time/lead-time.component';
import { StepIndicatorComponent } from './components/step-indicator/step-indicator.component';


@NgModule({
    declarations: [
        HeaderComponent,
        NavOptionsComponent,
        NumberOnlyInputDirective,
        AlertComponent,
        ModalComponent,
        SearchComponent,
        BackButtonComponent,
        CurrencyNLPipe,
        DateNLPipe,
        PercentageNLPipe,
        FlagNLPipe,
        CustomTitleCasePipe,
        CookieNotificationComponent,
        ContactFormComponent,
        MyProfileComponent,
        PasswordValidatorComponent,
        FileUploadComponent,
        FeedbackComponent,
        FeedbackRatingComponent,
        FaqComponent,
        ScrollTrackerDirective,
        AutoSearchComponent,
        ClickOutsideDirective,
        LeadTimeComponent,
        StepIndicatorComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        PaginationModule.forRoot(),
        AccordionModule.forRoot(),
        TabsModule.forRoot(),
        TooltipModule.forRoot(),
        ModalModule.forRoot(),
        BsDropdownModule.forRoot(),
        RecaptchaModule,
        BreadcrumbModule
    ],
    exports: [
        HeaderComponent,
        NavOptionsComponent,
        AlertComponent,
        ModalComponent,
        SearchComponent,
        BackButtonComponent,
        CookieNotificationComponent,
        NumberOnlyInputDirective,
        CurrencyNLPipe,
        DateNLPipe,
        PercentageNLPipe,
        FlagNLPipe,
        CustomTitleCasePipe,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        PaginationModule,
        AccordionModule,
        TabsModule,
        TooltipModule,
        ModalModule,
        ContactFormComponent,
        BsDropdownModule,
        MyProfileComponent,
        PasswordValidatorComponent,
        FileUploadComponent,
        FeedbackComponent,
        FeedbackRatingComponent,
        FaqComponent,
        ScrollTrackerDirective,
        AutoSearchComponent,
        ClickOutsideDirective,
        LeadTimeComponent,
        StepIndicatorComponent
    ]
})
export class SharedModule { }
