import { FetchClient } from '@yellowtail-frontend/cord-connector';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { MortgageRequestList, MortgageRequestDocumentResponse, DocumentUploadRequest, DocumentDownloadRequest } from '../models/mortgage-request.model';

@Injectable({
    providedIn: 'root'
})

export class MortgageRequestService extends FetchClient {

    constructor() {
        super();
        this.baseUrl = environment.mortgageRequestBaseUrl;
    }

    getMortgageRequest(organizationId: string): Promise<MortgageRequestList> {
        return this.get(`MortgageRequest/${organizationId}`);
    }

    getMortgageRequestDocuments(organizationId: string, applicationId: string): Promise<MortgageRequestDocumentResponse> {
        return this.get(`Document/${organizationId}/${applicationId}`);
    }

    downloadMortgageRequestDocument(organizationId: string, applicationId: string, documentId: string): Promise<DocumentDownloadRequest> {
        return this.get(`Document/download/${organizationId}/${applicationId}/${documentId}`);
    }

    uploadMortgageRequestDocument(documentRequest: DocumentUploadRequest): Promise<string> {
        return this.put(`Document/upload`, documentRequest);
    }

}
