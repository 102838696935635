import { Component, ViewChild, OnInit } from '@angular/core';
import { ModalHelperService } from './modules/shared/components/modal/modal-helper.service';
import { RaboWrapperService } from './modules/shared/services/rabo-wrapper.service';
import { Router } from '@angular/router';
import { ModalComponent } from 'src/app/modules/shared/components/modal/modal.component';
import { OnboardingTourService } from './modules/shared/services/onboarding-tour.service';
import { TealiumAnalyticsService } from './modules/shared/services/tealium-analytics.service';
import { AccessManagerService } from './modules/shared/services/access-manager.service';
import { UiHelperService } from './modules/shared/services/ui-helper.service';
import { Configuration, StorageKeys } from './modules/shared/enums/enums';
import { MaintenanceBannerDetail, ReleaseVersion } from './modules/shared/models/generic.model';
import { timeIntervalForNewRelease } from 'src/environments/environment.global';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
    title = 'rabobank-intermediair';
    releaseVersion: string;

    @ViewChild('modalTerms', { static: true }) private readonly modalTerms: ModalComponent;
    @ViewChild('modalSessionExpired', { static: true }) private readonly modalSessionExpired: ModalComponent;
    @ViewChild('modalNewRelease', { static: true }) private readonly modalNewRelease: ModalComponent;

    constructor(
        private readonly _modalHelper: ModalHelperService,
        private readonly _raboWrapper: RaboWrapperService,
        private readonly _router: Router,
        public _onboardingTour: OnboardingTourService,
        public _tealiumAnalytics: TealiumAnalyticsService,
        private readonly _accessManager: AccessManagerService,
        private readonly _uiHelper: UiHelperService
    ) {
        this.getReleaseVersion();
        this.isNewReleaseAvailable();
        this.getMaintenanceConfig();
        this._router.events.subscribe(() => {
            this._uiHelper.toggleUnauthorized$.next(false);
        });
    }

    ngOnInit() {
        this._modalHelper.toggleLoadingModal$.subscribe(value => {
            if (value) {
                document.body.classList.add('loading-state');
            }
            else {
                document.body.classList.remove('loading-state');
            }
        });

        this._modalHelper.toggleSessionExpiredModal$.subscribe(value => {
            if (value) {
                this.modalSessionExpired.show();
            }
            else {
                this.modalSessionExpired.hide();
            }
        });
    }

    getMaintenanceConfig() {
        this._raboWrapper.getConfig(Configuration.MaintenanceConfiguration).then(resp => {
            const maintenanceBannerDetail = resp as MaintenanceBannerDetail;
            if (maintenanceBannerDetail?.MaintenancePageUpMajor) {
                this._router.navigate(['/maintenance'], { queryParams: { view: 'majorDisruption' } });
            }
            else if (maintenanceBannerDetail?.MaintenancePageUpMinor) {
                this._router.navigate(['/maintenance']);
            }
            this._accessManager.maintenanceBannerDetail$.next(maintenanceBannerDetail);
        }).catch((err) => {
            if (!err.Result) {
                this._router.navigate(['/maintenance'], { queryParams: { view: 'unAuthUser' } });
            }
        });
    }

    onClickRedirectToLogin() {
        location.href = './login';
    }

    getReleaseVersion() {
        this._raboWrapper.getConfig(Configuration.ReleaseVersion).then((res) => {
            const releaseVersion = res as ReleaseVersion;
            sessionStorage.setItem(StorageKeys.ReleaseVersion, releaseVersion.Version);
            this._uiHelper.releaseVersion$.next(releaseVersion.Version);
        });
    }

    isNewReleaseAvailable() {
        setInterval(() => {
            this.releaseVersion = sessionStorage.getItem(StorageKeys.ReleaseVersion);
            this._raboWrapper.getConfig(Configuration.ReleaseVersion).then((res) => {
                const releaseVersion = res as ReleaseVersion;
                if (releaseVersion.Version !== this.releaseVersion) {
                    this.releaseVersion = releaseVersion.Version;
                    this.modalNewRelease.show();
                }
            });
        }, timeIntervalForNewRelease);
    }

    reload() {
        sessionStorage.setItem(StorageKeys.ReleaseVersion, this.releaseVersion);
        this.modalNewRelease.hide();
        window.location.reload();
    }
}
