//version 2.0.14
(function () {
    "use strict";

    function checkCookieValue(name) {
        const regex = /^[a-zA-Z]+$/;
        if (typeof name !== "undefined") {
            if (regex.test(name)) {
                if (!String.prototype.isInList) {
                    String.prototype.isInList = function () {
                        const value = this.valueOf();
                        for (let i = 0, l = arguments.length; i < l; i += 1) {
                            if (arguments[i] === value) return true;
                        }
                        return false;
                    }
                }
                if (name.isInList("qa", "performance")) {
                    return name;
                }
            }
        }
    }

    function getUtagPerfCookie(name) {
        let end;
        const dc = document.cookie;
        const prefix = name + "=";
        let begin = dc.indexOf("; " + prefix);
        if (begin == -1) {
            begin = dc.indexOf(prefix);
            if (begin != 0) return null;
        } else {
            begin += 2;
            end = document.cookie.indexOf(";", begin);
            if (end == -1) {
                end = dc.length;
            }
        }
        return checkCookieValue(decodeURI(dc.substring(begin + prefix.length, end)));
    }
    const docDomain = location.hostname;
    const ACCOUNT = "rabobank",
        UTAG = "/utag.js";
    let profile = null,
        environment = null,
        tealiumEnabled = false,
        PATH;
    const utagEnvironment = getUtagPerfCookie("utagRbEnv");
    const utagProfile = getUtagPerfCookie("utagRbProf");
    if (utagProfile != null) {
        profile = utagProfile;
    }
    const productionDomains = {
        "rabobankintermediairomgeving.nl": ["rio.rabobank.nl"]
    };
    const testDomains = {
        "rabobankintermediairomgeving.nl": ["acc.rio.rabobank.nl"]
    };
    if (!setVariables(productionDomains, "prod")) {
        setVariables(testDomains, "qa");
    }
    if (utagEnvironment != null) {
        environment = utagEnvironment;
    }
    if (tealiumEnabled) {
        PATH = "//tags.tiqcdn.com/utag/" + ACCOUNT + "/" + profile + "/" + environment;
        insertScript(UTAG);
        document.addEventListener("sendDataView", viewEventHandler);
        document.addEventListener("sendTealiumViewEvent", viewEventHandler);
    }

    function insertScript(file) {
        let element = document.createElement("script");
        element.src = PATH + file;
        element.type = "text/javascript";
        element.async = true;
        element.onload = function () {
            document.tealiumLoaded = true;
            document.dispatchEvent(new CustomEvent("tealiumLoaded"));
        };
        document.head.appendChild(element);
    }
    window.utag_early_events = new Array;

    function viewEventHandler(event) {
        if (window.utag) {
            checkEventDetail(event);
        } else {
            window.utag_early_events.push(event.detail);
        }
    }

    function checkEventDetail(event) {
        if (typeof event.detail.event_store != "undefined" && event.detail.event_store != "") {
            delete event.detail.event_store;
            if (typeof tealiumTools != "undefined" && typeof tealiumTools.store != "undefined") {
                tealiumTools.store(event.detail);
            }
        } else if (typeof event.detail != "undefined" && typeof event.detail.event_category != "undefined" && event.detail.event_category != "" || typeof event.detail.promotion_action != "undefined" && typeof event.detail.promotion_id != "undefined" && typeof event.detail.promotion_name != "undefined" || typeof event.detail.component_action != "undefined" && event.detail.component_action == "click" || typeof event.detail.product_action != "undefined" && event.detail.product_action == "click") {
            window.utag.link(event.detail);
        } else {
            window.utag.view(event.detail);
        }
    }

    function setVariables(domainList, environmentName) {
        for (const domain in domainList) {
            for (const subDomain in domainList[domain]) {
                if (docDomain == domainList[domain][subDomain]) {
                    profile = domain;
                    environment = environmentName;
                    tealiumEnabled = true;
                    return true;
                }
            }
        }
        tealiumEnabled = false;
        return false;
    }
})();

let analytics = new Object();
analytics.measure = function (data) {
    let event = new CustomEvent('sendDataView', { detail: data });
    document.dispatchEvent(event);
}