import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CordFinanceService, CordIamService, CordStatusService, CordDocsService } from '@yellowtail-frontend/cord-connector';
import { environment } from 'src/environments/environment';

import { SharedModule } from './modules/shared/shared.module';

import { GuidedTourModule, GuidedTourService } from 'ngx-guided-tour';
import { LoginRedirectComponent } from './modules/login-redirect/login-redirect.component';
import { InstancesManagerService } from './modules/shared/services/instances-manager.service';
import { ErrorHandlerService } from './modules/shared/services/error-handler.service';

@NgModule({
    declarations: [
        AppComponent,
        LoginRedirectComponent
    ],
    imports: [
        BrowserAnimationsModule,
        SharedModule,
        AppRoutingModule,
        GuidedTourModule,
    ],
    providers: [
        { provide: CordFinanceService, useValue: new CordFinanceService(environment.cordFinanceBaseUrl) },
        { provide: CordIamService, useValue: new CordIamService(environment.cordIamBaseUrl) },
        { provide: CordStatusService, useValue: new CordStatusService(environment.cordStatusBaseUrl) },
        { provide: CordDocsService, useValue: new CordDocsService(environment.cordDocsBaseUrl) },
        GuidedTourService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor(
        private _instancesManager: InstancesManagerService,
        private _errorHandler: ErrorHandlerService
        ) { }
}
