import { Component, Input, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ModalHelperService } from './modal-helper.service';

@Component({
    selector: 'app-modal',
    templateUrl: './modal.component.html'
})
export class ModalComponent {
    @Input() config: any = { backdrop: 'static' };
    @Input() dialogClasses: string = '';
    @Input() modalType: string = 'Default'; // Default, Loading
    @ViewChild('modal', { static: false }) modal: ModalDirective;

    constructor(
        private _modalHelper: ModalHelperService
    ) { }

    show() {
        this._modalHelper.openModal(this.modal);
    }

    hide() {
        this._modalHelper.closeModal(this.modal);
    }

}
