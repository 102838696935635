import { Injectable } from '@angular/core';
import { UiHelperService } from './ui-helper.service';
import { FetchService } from '@yellowtail-frontend/cord-connector';
import { ModalHelperService } from '../components/modal/modal-helper.service';
import { AccessManagerService } from './access-manager.service';

@Injectable({
    providedIn: 'root'
})
export class ErrorHandlerService {

    tokenExpirationModalTimeout: any;
    constructor(
        private _uiHelper: UiHelperService,
        private _modalHelper: ModalHelperService,
        public _accessManager: AccessManagerService
    ) {
        new FetchService().handleError = (error) => {
            if ((error?.result?.StatusCode === 401 || error?.result?.Code === 401) && (error?.result?.Message || '').toUpperCase() === 'TOKEN IS EXPIRED') {
                this._modalHelper.toggleSessionExpiredModal$.next(true);
                this._uiHelper.clearSession(false);
                setTimeout(() => {
                    location.href = './login';
                }, 10000);
            }

            if (error.response?.status === 401 && error.result) {
                this._uiHelper.toggleUnauthorized$.next(true);
            }
        };

        this._accessManager.tokenExpiredModal$.subscribe(value => {
            if (value) {
                if (this.tokenExpirationModalTimeout) {
                    clearTimeout(this.tokenExpirationModalTimeout);
                }
                this.tokenExpirationModalTimeout = setTimeout(() => {
                    this._modalHelper.toggleSessionExpiredModal$.next(true);
                    this._uiHelper.clearSession(false);
                }, value);
            }
        });
    }
}
