import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccessManagerService } from '../shared/services/access-manager.service';
import { UiHelperService } from '../shared/services/ui-helper.service';
import { CordIamService } from '@yellowtail-frontend/cord-connector';
import { ModalComponent } from '../shared/components/modal/modal.component';
import { ModalHelperService } from '../shared/components/modal/modal-helper.service';
import { Provider } from '../shared/enums/enums';

@Component({
  selector: 'app-login-redirect',
  templateUrl: './login-redirect.component.html'
})
export class LoginRedirectComponent implements OnInit {

  code: string;
  state: string;

  @ViewChild('modalError', { static: true }) private modalError: ModalComponent;

  constructor(private _activatedRoute: ActivatedRoute,
    private readonly _cordIamService: CordIamService,
    private readonly _router: Router,
    private readonly _accessManager: AccessManagerService,
    private readonly _uiHelper: UiHelperService,
    private readonly _modalHelper: ModalHelperService,
  ) {
    this.code = this._activatedRoute.snapshot.queryParamMap.get('code');
    this.state = this._activatedRoute.snapshot.queryParamMap.get('session_state');
    if (!this.code || !this.state) {
      this._router.navigate(['/login/overview']);
    }
  }

  ngOnInit(): void {
    this._modalHelper.toggleLoadingModal$.next(true);
    this._cordIamService.loginWithOAuthCode(Provider, this.code).then((res) => {
      this._accessManager.setAuthResult(res);
      this._uiHelper.navigateUserToPortal(this._accessManager.user$.value?.type);
    })
      .catch(() => this.modalError.show())
      .finally(() => this._modalHelper.toggleLoadingModal$.next(false));
  }

  onClickRedirectToLogin() {
    this._uiHelper.clearSession();
  }
}
