import { baseUrl } from "./environment.global";

export const environment = {
    production: false,
    name: 'acc',
    cordFinanceBaseUrl: `${baseUrl.acc}cordfinance/api`,
    cordIamBaseUrl: `${baseUrl.acc}access-identity/api`,
    cordStatusBaseUrl: `${baseUrl.acc}status/api`,
    cordDocsBaseUrl: `${baseUrl.acc}docs/api`,
    raboWrapperBaseUrl: `${baseUrl.acc}rabobank/api`,
    umbracoNewsBaseUrl: 'https://rabo-acc-umbraco.azurewebsites.net/raboumbraco/api',
    reCaptchaSiteKey: '6LeeFkEaAAAAAGf5MxRKZHn654yvPxj-mAwpkz6O',
    mortgageRequestBaseUrl: `${baseUrl.acc}mortgage-request/api`,
    routeUrl: `${baseUrl.acc}`
};
