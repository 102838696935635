import { Injectable } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ModalHelperService {
    openedModal: ModalDirective;
    toggleLoadingModal$ = new Subject<boolean>();
    toggleSessionExpiredModal$ = new Subject<boolean>();


    openModal(modal: ModalDirective) {
        if (modal) {

            if (modal.isShown) { // To prevent scroll issue that causes when trying to show a modal which is already opened.
                return false;
            }

            if (this.openedModal) {
                this.openedModal.hide();
            }

            modal.show();
            this.openedModal = modal;
        }
    }

    closeModal(modal: ModalDirective) {
        if (modal) {
            modal.hide();
            this.openedModal = null;
        }
    }
}
