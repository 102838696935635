import { Injectable } from '@angular/core';
import { LoginStatus, StorageKeys, UserType } from '../enums/enums';
import { BehaviorSubject } from 'rxjs';
import { CookieService } from './cookie.service';
import { RaboWrapperService } from './rabo-wrapper.service';
import { User } from '../models/user.model';
import { MaintenanceBannerDetail } from '../models/generic.model';

@Injectable({
    providedIn: 'root'
})
export class AccessManagerService {
    private twoFaToken: string;
    isTwoFaConfigured: boolean;
    private userToken: string;
    public lastLoggedIn: string;
    userProfile$ = new BehaviorSubject<User>(null);
    twoFaToken$ = new BehaviorSubject<string>('');
    userToken$ = new BehaviorSubject<string>('');
    loginStatus$ = new BehaviorSubject<LoginStatus>(null);
    user$ = new BehaviorSubject<{ type: UserType, id: string, roles: string[] }>(null);
    tokenExpiredModal$ = new BehaviorSubject<number>(0);
    enableMortgageRequest: boolean;
    userId: string;

    maintenanceBannerDetail$ = new BehaviorSubject<MaintenanceBannerDetail>(null);

    constructor(
        private _cookie: CookieService,
        private _raboWrapper: RaboWrapperService
    ) {
        this.twoFaToken = this._cookie.get('c_tfa_token');
        this.isTwoFaConfigured = this._cookie.get('c_tfa_configured') === 'true';
        this.userToken = this._cookie.get('c_user_token');
        this.lastLoggedIn = this._cookie.get('lastLoggedIn');

        if (this.validateToken(this.userToken)) {
            this.setUser(this.userToken);
            this.userToken$.next(this.userToken);
            this.setTokenExpiration(this.userToken);
            this.loginStatus$.next(LoginStatus.LoggedIn);
            this.getUserProfile();
        }
        else if (this.validateToken(this.twoFaToken)) {
            this.twoFaToken$.next(this.twoFaToken);
            this.setTokenExpiration(this.twoFaToken);
            this.loginStatus$.next(LoginStatus.TwoFaPending);
        }
    }

    setLoginResult(loginResult: any) {
        if (loginResult) {
            this.twoFaToken = loginResult.Token;
            this.isTwoFaConfigured = !!loginResult.IsTwoFactorAuthConfigured;
            this.twoFaToken$.next(this.twoFaToken);
            this.setTokenExpiration(this.twoFaToken);
            this.loginStatus$.next(LoginStatus.TwoFaPending);
            this._cookie.set('c_tfa_token', this.twoFaToken, 1, '/');
            this._cookie.set('c_tfa_configured', '' + this.isTwoFaConfigured, 1, '/');
        }
    }

    setAuthResult(authResult: any) {
        if (authResult) {
            this.userToken = authResult.Token;
            this.setUser(this.userToken);
            this.setTokenExpiration(this.userToken);
            this.userToken$.next(this.userToken);
            this.lastLoggedIn = authResult.LastLoggedInOn;
            this._cookie.set('lastLoggedIn',this.lastLoggedIn);
            this.loginStatus$.next(LoginStatus.LoggedIn);
            this._cookie.set('c_user_token', this.userToken, 1, '/');
            this._cookie.set('c_tfa_configured', '' + true, 1, '/');
            this._cookie.delete('c_tfa_token', '/');
            this.getUserProfile();
        }
    }

    private setUser(token: string) {
        if (token) {
            const decodedToken = JSON.parse(window.atob(token.split('.')[1]));
            const context = decodedToken.context;
            const roles = decodedToken.roles;
            this.user$.next({ type: UserType[context.type as string], id: decodedToken.sub, roles: roles });
        }
    }

    private validateToken(token: string): boolean {
        if (token && token !== 'undefined' && new Date(JSON.parse(window.atob(token.split('.')[1])).exp).getTime() > new Date().getTime()) {
            return true;
        }

        return false;
    }

    getUserProfile() {
        setTimeout(() =>
            this._raboWrapper.getProfile().then((resp: User) => {
                this.userProfile$.next(resp);
                if (resp.Organisation) {
                    sessionStorage.setItem(StorageKeys.OrganizationId, resp.Organisation.ID);
                    this.enableMortgageRequest = resp.Organisation?.EnableMortgageRequest;
                }
            }), 0);
    }


    private setTokenExpiration(token: string) {
        if (token) {
            const tokenObject = JSON.parse(window.atob(token.split('.')[1]));
            const currentTime = new Date();
            const expirationTime = new Date(tokenObject.exp);
            const tokenAge = (expirationTime.getTime() - currentTime.getTime());
            this.tokenExpiredModal$.next(tokenAge);
        }
    }

    get isAdmin() {
        return this.user$.value?.type === UserType.SuperUser;
    }

    get isSuperAgent() {
        return this.user$.value?.type === UserType.SuperAgent;
    }
}
