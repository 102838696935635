@if (modalType==='Default') {
  <div class="modal fade" bsModal #modal="bs-modal" [config]="config" role="dialog">
    <div class="modal-dialog" [ngClass]="dialogClasses">
      <div class="modal-content">
        <ng-content select=".modal-header"></ng-content>
        <ng-content select=".modal-body"></ng-content>
        <ng-content select=".modal-footer"></ng-content>
      </div>
    </div>
  </div>
}

@if (modalType==='Loading') {
  <div class="modal fade modal-center" bsModal #modal="bs-modal" [config]="config" role="dialog">
    <div class="modal-dialog" [ngClass]="dialogClasses">
      <div class="modal-content">
        <div class="modal-body modal-loading">
          <img src="assets/img/loading.png" class="loading-icon" alt="Loading Icon" />
          <ng-content></ng-content>
        </div>
      </div>
    </div>
  </div>
}

