import { Injectable } from '@angular/core';
import { AccessManagerService } from './access-manager.service';
import { CordFinanceService, CordStatusService, CordIamService, CordDocsService } from '@yellowtail-frontend/cord-connector';
import { RaboWrapperService } from './rabo-wrapper.service';
import { MortgageRequestService } from './mortgage-request.service';

@Injectable({
    providedIn: 'root'
})
export class InstancesManagerService {

    constructor(
        private _accessManager: AccessManagerService,
        private _cordFinance: CordFinanceService,
        private _cordStatus: CordStatusService,
        private _cordIam: CordIamService,
        private _raboWrapper: RaboWrapperService,
        private _cordDocs: CordDocsService,
        private _mortgagerequest:MortgageRequestService
    ) {
        this._accessManager.userToken$.subscribe((token: string) => {
            if (token) {
                this._cordFinance.appendToken(token);
                this._cordStatus.appendToken(token);
                this._cordIam.appendToken(token);
                this._raboWrapper.appendToken(token);
                this._cordDocs.appendToken(token);
                this._mortgagerequest.appendToken(token);
            }
        });

        this._accessManager.twoFaToken$.subscribe((token: string) => {
            if (token) {
                this._cordIam.appendToken(token);
            }
        });
    }
}
