import { Injectable } from '@angular/core';
import { GuidedTour, GuidedTourService, ProgressIndicatorLocation } from 'ngx-guided-tour';
import { ITourOtherConfiguration } from '../models/tour-other-configuration.model';
import { CookieService } from './cookie.service';
import { CookieKeys } from '../enums/enums'
import { RaboWrapperService } from 'src/app/modules/shared/services/rabo-wrapper.service';
import { TourDetails } from '../models/user.model';
import { UiHelperService } from './ui-helper.service';


@Injectable({ providedIn: 'root' })
export class OnboardingTourService {

    nextButtonText: string;
    doneButtonText = 'Klaar';
    progressIndicatorLocation: ProgressIndicatorLocation;
    configuration: ITourOtherConfiguration;
    tourDetails: TourDetails = {
        InternalId: '',
        UserID: '',
        Status: false,
        CreatedDate: null
    };

    //@ViewChild('drpDown') public drpDown: BsDropdownDirective

    constructor(
        private readonly _guidedTour: GuidedTourService,
        private readonly _cookie: CookieService,
        private readonly _raboWrapper: RaboWrapperService,
        private readonly _uiHelperService: UiHelperService
    ) { }

    startTour(tour: GuidedTour) {
        this._guidedTour.startTour(tour);
    }

    /* Steps callbacks*/
    action = () => {
        setTimeout(() => {

            /* Set next button texts. Add more cases as per requirement. */
            if (this.configuration?.ButtonText[`next-button-text-step-${this._guidedTour.currentTourStepDisplay}`]) {
                this.nextButtonText = this.configuration.ButtonText[`next-button-text-step-${this._guidedTour.currentTourStepDisplay}`];
            } else {
                this.nextButtonText = 'Volgende';
            }

            if (this._guidedTour.currentTourStepDisplay === 6) {
                this._uiHelperService.isDropDown$.next(true);
            }

        }, 0);
    }

    /* GuidedTour callbacks*/
    completeTourCallBack = () => {
        this.setTourDisplayStatus();
    }

    setTourDisplayStatus() {
        this.tourDetails.Status = true;
        this._raboWrapper.saveTourStatus(this.tourDetails.Status).catch(_ => this.tourDetails.Status = false);
    }

    closeWelcomeBoxState() {
        this._cookie.set(CookieKeys.WelcomeBoxState, 'true', 1, '/');
    }

    getWelcomeBoxState(): boolean {
        return this._cookie.get(CookieKeys.WelcomeBoxState) === 'true';
    }

    getTourStatus() {
        this._raboWrapper.getTourStatus().then(resp => this.tourDetails = resp);
    }
}
