import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
declare let analytics: any;

@Injectable({
    providedIn: 'root'
})
export class TealiumAnalyticsService {

    // Key: EventId, Value: [EventCategory, EventAction, EventLabel]
    private readonly gaEvents = {

        // Login
        '0.1': ['button', 'button click', 'Inloggen'],
        '0.2': ['link', 'link click', 'Wachtwoord vergeten?'],
        '0.3': ['link', 'link click', 'Problemen met inloggen'],
        '0.4': ['link', 'link click', 'Nog geen aansluiting bij Rabobank? Vraag direct aan!'],
        '0.5': ['link', 'link click', 'Inloggen als Rabobank medewerker'],
        '0.6': ['link', 'link click', 'Voorwaarden'],
        '0.7': ['link', 'link click', 'Disclaimer'],
        '0.8': ['link', 'link click', 'Privacy & cookies'],

        // Forgot password page
        '0.2.1': ['button', 'button click', 'Verstuur'],

        // Faq page
        '0.3.1': ['accordion', 'accordion <open/close>', 'Foutmelding combinatie e-mailadres en wachtwoord niet correct'],
        '0.3.2': ['accordion', 'accordion <open/close>', 'Wachtwoord vergeten'],
        '0.3.3': ['accordion', 'accordion <open/close>', 'SMS niet goed ontvangen'],
        '0.3.4': ['accordion', 'accordion <open/close>', 'Gebruikersnaam vergeten'],
        '0.3.5': ['accordion', 'accordion <open/close>', 'Twee-factor-authenticatie niet (meer)'],
        '0.3.6': ['accordion', 'accordion <open/close>', 'De pagina wordt niet juist getoond'],
        '0.3.7': ['link', 'link click', 'Reset hier je wachtwoord'],
        '0.3.8': ['link', 'link click', 'Vraag hier een nieuwe SMS aan'],
        '0.3.9': ['link', 'link click', 'hier'],
        '0.3.10': ['link', 'link click', 'Naar het contactformulier'],
        '0.3.11': ['accordion', 'accordion <open/close>', 'Account geblokkeerd'],


        // Intermediary welcome page
        '1.1': ['button', 'button click', 'Rabobank Intermediair Omgeving'],
        '1.2': ['link', 'link click', 'Overzicht'],
        '1.3': ['link', 'link click', 'Aanvragen'],
        '1.4': ['link', 'link click', 'Klanten'],
        '1.5': ['link', 'link click', 'Contact'],
        '1.6': ['link', 'link click', 'Profiel naam'],
        '1.7': ['link', 'link click', 'Uitloggen'],
        '1.8': ['link', 'link click', 'Naar beheeromgeving'],

        // Intermediary contact page
        '1.5.1': ['accordion', 'accordion <open/close>', "Kan ik documenten uploaden en inzien (manco's) in RIO"],
        '1.5.2': ['accordion', 'accordion <open/close>', 'Kan ik inzien welke documenten ontbreken bij een aanvraag'],
        '1.5.3': ['accordion', 'accordion <open/close>', 'Ik heb een inhoudelijke vraag over een dossier'],
        '1.5.4': ['accordion', 'accordion <open/close>', 'Ik krijg een (technische) foutmeleding tijdens het uploaden. Wat kan ik doen?'],
        '1.5.5': ['accordion', 'accordion <open/close>', 'Mijn aanvraag ontbreekt'],
        '1.5.6': ['link', 'link click', 'Neem contact'],
        '1.5.7': ['link', 'link click', 'Naar contactformulier'],
        '1.5.8': ['link', 'link click', 'Gebruikershandleiding'],
        '1.5.9': ['link', 'link click', 'Rabobank Intermediairdesk'],
        '1.5.12': ['accordion', 'accordion <open/close>', 'Uploaden en bekijken van documenten'],

        // Intermediary contact form page
        '1.5.10': ['button', 'button click', 'Verzenden'],
        '1.5.11': ['link', 'link click', 'Bijlage toevoegen'],

        // Intermediary overzicht page
        '1.2.1': ['form', 'field interaction', 'Zoek op achternaam'],
        '1.2.2': ['link', 'link click', 'de beheeromgeving'],
        '1.2.3': ['button', 'button click', 'Meer doorlooptijden'],
        '1.2.4': ['button', 'button click', 'Bekijk alle aanvragen'],
        '1.2.5': ['button', 'button click', 'Bekijk alle klanten'],
        '1.2.6': ['link', 'link click', 'Rente'],
        '1.2.7': ['link', 'link click', 'Ondernemers'],
        '1.2.8': ['link', 'link click', 'Acceptatie voorwaarden'],
        '1.2.9': ['link', 'link click', 'Tools & handleiding'],
        '1.2.10': ['link', 'link click', 'Informatiekaart'],
        '1.2.11': ['link', 'link click', 'Formulieren'],
        '1.2.12': ['link', 'link click', 'Explain'],
        '1.2.13': ['link', 'link click', 'Formulieren & tools'],

        // Intermediary customers page
        '1.4.1': ['form', 'field interaction', 'Zoek op naam of postcode'],
        '1.4.2': ['dropdown', 'dropdown click', 'Filter + Selecteer alles'],
        '1.4.3': ['dropdown', 'dropdown click', 'Filter + Einde rentevaste periode'],
        '1.4.4': ['dropdown', 'dropdown click', 'Filter + Einde looptijd'],
        '1.4.5': ['button', 'button click', 'Klant bekijken'],

        // Intermediary customer page
        '2.1': ['link', 'link click', 'Download klantrapport'],
        '2.2': ['accordion', 'accordion <open/close>', 'Hypotheek details'],

        // Intermediary admin overview page
        '3.1': ['link', 'link click', 'Nieuwe adviseur toevoegen'],
        '3.2': ['form', 'field interaction', 'Zoek op naam of e-mailadres'],
        '3.3': ['link', 'link click', 'Naar veelgestelde vragen'],
        '3.4': ['link', 'link click', 'Naar gebruikershandleiding'],
        '3.5': ['link', 'link click', 'Naar klantomgeving'],
        '3.6': ['button', 'button click', 'Aanpassen gegevens gebruiker'],
        '3.7': ['button', 'button click', 'Bevestig adviseurs'],
        '3.8': ['button', 'button click', 'Deblokkeren'],

        // Admin intermediary
        '4.1': ['form', 'field interaction', 'Searchfilter intermediair'],

        // Dynamic lable link click
        '5.1': ['link', 'link click'],

        // Intermediary mortgage request page 
        '6.1': ['button', 'button click', 'Aanvraag bekijken'],
        '6.2': ['dropdown', 'dropdown click'],

        // Intermediary mortgage request detail page 
        '7.1': ['form', 'field interaction', 'Document filter'],
        '7.2': ['button', 'button click', 'Document bekijken'],
        '7.3': ['button', 'button click', 'Document uploaden'],

        //Rente page
        '8.1': ['link', 'link click', 'Actuele rente']
    };

    logEvent(eventId: string, label?: string) {
        if ((environment.name === 'acc' || environment.name === 'prod') && this.gaEvents[eventId]) {
            const [eventCategory, eventAction, eventLabel] = this.gaEvents[eventId];
            const eventLabelText = label ?? eventLabel;
            this.sendEvent(eventCategory, eventAction, eventLabelText);
        }
    }

    private sendEvent(eventCategory: string, eventAction: string, eventLabel: string) {
        const details = {
            event_category: eventCategory,
            event_action: eventAction,
            event_label: eventLabel,
            page_name: window.location.href
        };
        analytics.measure(details);
    }
}
